.button.blue {
    width: 314px;
    height: 60px;
    border-radius: 5px;
    background: linear-gradient(180deg, #012F51, #014886);
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
}
.button.blue {
    width: 314px;
    height: 60px;
    border-radius: 5px;
    background: linear-gradient(180deg, #012F51, #014886);
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.button.blue:hover {
    background: #014886;
}

.button.blue:focus {
    outline: none;
}

.button.blue.hollow {
    width: 314px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #013A68;
    background: transparent;
    color: #013A68;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
}

.button.blue.hollow:focus {
    outline: none;
}

.button.red.hollow {
    width: 314px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #B90000;
    background-color: transparent;
    color: #B90000;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button.red.hollow:focus {
    outline: none;
}

.button.red.hollow:active {
    background-color: #FF0000;
}

.button {
    all: unset;
    /* background-color: rgba(1, 72, 134, 1); */
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 5px;
    box-shadow: 0px 4px 4px #0000000d;
    box-sizing: border-box;
    height: 60px;
    width: 314px;
    text-align: center;
    margin: auto;
    color: white;
    font-family: Inter;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
}
.button.green{
    all: unset;
    /* background-color: rgba(1, 72, 134, 1); */
    background: linear-gradient(180deg, rgb(9, 142, 0) 0%, rgb(16, 197, 0) 100%);
    border-radius: 5px;
    box-shadow: 0px 4px 4px #0000000d;
    box-sizing: border-box;
    height: 60px;
    width: 314px;
    text-align: center;
    margin: auto;
    color: white;
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
}

.button .text-wrapper {
    color: #9f9fa1;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    left: 107px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: left;
    top: 15px;
}

button.button.button-instance {
    width: 70%;
    display: flex;
    direction: rtl;
    position: inherit;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 0;
}
button.button.button-instance.disabled {
    background:linear-gradient(180deg, rgb(110, 156, 192) 0%, rgb(170, 208, 240) 100%);
}

.text-element-class {
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0;
    text-align: center;
}