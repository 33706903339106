.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
}

.home .rectangle {
  background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
  border-radius: 0px 0px 5px 5px;
  height: 29px;
  transform: rotate(-180deg);
  width: 100%;

  position: fixed;
  margin-top: 41px;
  padding: 0;
  bottom: 0;
}