.ca-third {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.ca-third .shiftAvailability-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    position: relative;
}
.ca-third .shiftAvailability-wrapper input{
    width: 20px;
    height: 20px;
}
.ca-third .shiftAvailability-wrapper label{
    font-weight: 700;
    font-size: 20px;
    padding-right: 5px;
}

.ca-third .shiftAvailability-wrapper .shiftAvailability-input{
    padding-top: 5px;
}
.ca-third .shiftAvailability-wrapper .unavailable{
    padding-left: 40px;
}
.ca-third .shiftAvailability-wrapper .shiftAvailability-inputs-wrapper{
    display: flex;
}

.ca-third .div-2 {
    background-color: #ffffff;
    height: 100%;
    width: 414px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ca-third .progress-one {
    position: relative;
    text-align: center;
    top: -30px;
    
}
.ca-third .steps-btn-container {
    position: absolute;
    top: 30px;
    width:100%;
    height: 50px;
    justify-content: space-between;
    display:flex;
    align-items: center;
}
.ca-third .steps-btn-container .box{
    /* margin-top: 40px; */
    width: 33%;
    height: 50px;
}

.ca-third .rectangle-4 {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 0px 0px 5px 5px;
    height: 29px;
    width: 100%;
}

.ca-third .group {
    height: 15px;
    left: 51px;
    position: absolute;
    top: 71px;
    width: 313px;
}

.ca-third .overlap {
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 149px;
}

.ca-third .line-3 {
    height: 1px;
    left: 8px;
    object-fit: cover;
    position: absolute;
    top: 6px;
    width: 141px;
}

.ca-third .ellipse {
    background-color: #013d70;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 15px;
}

.ca-third .overlap-group-4 {
    height: 15px;
    left: 149px;
    position: absolute;
    top: 0;
    width: 164px;
}

.ca-third .line-4 {
    height: 1px;
    left: 11px;
    object-fit: cover;
    position: absolute;
    top: 6px;
    width: 141px;
}

.ca-third .ellipse-2 {
    background-color: #00840d;
    border-radius: 7.5px;
    height: 15px;
    left: 149px;
    position: absolute;
    top: 0;
    width: 15px;
}

.ca-third .ellipse-3 {
    background-color: #00840d;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 15px;
}

.ca-third .frame-5 {
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    width:90vw;
    max-width: 380px;
    padding-bottom: 100px;
}

.ca-third .frame-6 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.ca-third .text-wrapper-7 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: left;
    width: 100%;
}

.ca-third .text-wrapper-8 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: left;
    width: fit-content;
}

.ca-third .frame-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 100px;
    position: relative;
}

.ca-third .frame-7 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.ca-third .component-instance {
    position: relative !important;
}

.ca-third .component-21 {
    left: 249px !important;
}

.ca-third .component-23 {
    left: 152px !important;
}

.ca-third .group-2 {
    height: 22px;
    margin-right: -2px;
    position: relative;
    width: 227px;
}

.ca-third .text-wrapper-9 {
    color: #013d70;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.ca-third .design-component-instance-node {
    left: 203px !important;
    position: absolute !important;
    top: 0 !important;
}

.ca-third .overlap-wrapper {
    height: 60px;
    left: 50px;
    position: absolute;
    top: 589px;
    width: 316px;
}

.ca-third .overlap-2 {
    border-radius: 5px;
    height: 60px;
    position: relative;
    width: 314px;
}

.ca-third .rectangle-5 {
    background-color: #013d70;
    border-radius: 5px;
    height: 60px;
    left: 0;
    position: absolute;
    top: 0;
    width: 314px;
}

.ca-third .text-wrapper-10 {
    color: #ffffff;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    left: 107px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: left;
    top: 14px;
    width: 100px;
}