.cu-loading {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    width: 100vw;
    max-width: 414px;
    margin: auto;
}

.cu-loading .div {
    background-color: #ffffff;
    height: 719px;
    width: 100%;
    max-width: 414px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
}

.cu-loading .rectangle {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 0px 0px 5px 5px;
    height: 23px;
    width: 100%;
}
.cu-loading .progress-one {
    position: relative;
    text-align: center;
    top: -30px;
}

.cu-loading .cu-loading__text {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    left: 114px;
    letter-spacing: 0;
    line-height: normal;
    /* position: relative; */
    text-align: left;
    top: 181px;
}

.cu-loading .text-wrapper-2 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 144px;
    letter-spacing: 0;
    line-height: normal;
    /* position: relative; */
    text-align: left;
    top: 224px;
}

.cu-loading .group {
    height: 15px;
    left: 50px;
    /* position: relative; */
    top: 79px;
    width: 313px;
}

.cu-loading__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cu-loading .overlap {
    height: 15px;
    left: 0;
    /* position: relative; */
    top: 0;
    width: 149px;
}

.cu-loading .line {
    height: 1px;
    left: 8px;
    object-fit: cover;
    /* position: relative; */
    top: 6px;
    width: 141px;
}

.cu-loading .ellipse {
    background-color: #013a68;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    /* position: relative; */
    top: 0;
    width: 15px;
}

.cu-loading .overlap-group {
    height: 15px;
    left: 149px;
    /* position: relative; */
    top: 0;
    width: 164px;
}

.cu-loading .line-2 {
    height: 1px;
    left: 11px;
    object-fit: cover;
    /* position: relative; */
    top: 6px;
    width: 141px;
}

.cu-loading .ellipse-2 {
    background-color: #00840d;
    border-radius: 7.5px;
    height: 15px;
    left: 149px;
    /* position: relative; */
    top: 0;
    width: 15px;
}

.cu-loading .ellipse-3 {
    background-color: #00840d;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    /* position: relative; */
    top: 0;
    width: 15px;
}

/* img.progress-one {
    padding: 50px 0;
} */

.cu-loading .component {
    background-image: url(../../assets//Loader.svg) !important;
    height: 100px !important;
    left: 157px !important;
    top: 309px !important;
    width: 100px !important;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.cu-loading .div {
    background-color: #ffffff;
    height: 719px;
    width: 414px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
}

.cu-loading .cu-loading__text {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    left: 114px;
    letter-spacing: 0;
    line-height: normal;
    /* position: relative; */
    text-align: left;
    top: 181px;
}

.cu-loading .text-wrapper-2 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 144px;
    letter-spacing: 0;
    line-height: normal;
    /* position: relative; */
    text-align: left;
    top: 224px;
}

.cu-loading .group {
    height: 15px;
    left: 50px;
    /* position: relative; */
    top: 79px;
    width: 313px;
}

.cu-loading__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cu-loading .overlap {
    height: 15px;
    left: 0;
    /* position: relative; */
    top: 0;
    width: 149px;
}

.cu-loading .line {
    height: 1px;
    left: 8px;
    object-fit: cover;
    /* position: relative; */
    top: 6px;
    width: 141px;
}

.cu-loading .ellipse {
    background-color: #013a68;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    /* position: relative; */
    top: 0;
    width: 15px;
}

.cu-loading .overlap-group {
    height: 15px;
    left: 149px;
    /* position: relative; */
    top: 0;
    width: 164px;
}

.cu-loading .line-2 {
    height: 1px;
    left: 11px;
    object-fit: cover;
    /* position: relative; */
    top: 6px;
    width: 141px;
}

.cu-loading .ellipse-2 {
    background-color: #00840d;
    border-radius: 7.5px;
    height: 15px;
    left: 149px;
    /* position: relative; */
    top: 0;
    width: 15px;
}

.cu-loading .ellipse-3 {
    background-color: #00840d;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    /* position: relative; */
    top: 0;
    width: 15px;
}

.cu-loading .component {
    background-image: url(../../assets//Loader.svg) !important;
    height: 100px !important;
    left: 157px !important;
    top: 309px !important;
    width: 100px !important;
}

/* @media screen and (max-width: 430px) {
    img.progress-one {
        padding: 50px 0;
        width: 78%;
    }
} */