.invite-admin {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255, 0.9);
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.invite-admin_popup {
    background-color: #fff;
    height: 340px;
    top: 0;
    left: calc((100% - 375px) / 2);
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.invite-admin_rectangle {
    background-color: #013d70;
    height: 10px;
    left: 0;
    position: absolute;
    top: 0;
    width: 375px;
}

.invite-admin_button {
    border: none;
    background-color: #013d70;
    border-radius: 5px;
    height: 60px;
    width: 314px;
    color: #fff;
    font-family: "Inter-Regular", Helvetica;
    font-size: 22px;
}