.cu-success {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
}

.cu-success .div {
    background-color: #ffffff;
    height: 719px;
    width: 414px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.cu-success .rectangle {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 0px 0px 5px 5px;
    height: 23px;
    left: 0;
    top: 0;
    width: 100%;
}

.cu-success .text-wrapper {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin: 0 auto;
    /* height: 30px; */
}

.cu-success .text-wrapper-2 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 107px;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    top: 318px;
    width: 80%;
}

.cu-success .overlap {
    border-radius: 5px;
    height: 60px;
    left: 50px;
    top: 558px;
    width: 314px;
}

.cu-success .rectangle-2 {
    background-color: #013d70;
    border-radius: 5px;
    height: 60px;
    width: 314px;
    cursor: pointer;
    margin-top: 190px;
    text-align: center;
}
.cu-success .rectangle-3 {
    background-color: #013d70;
    border-radius: 5px;
    height: 60px;
    width: 314px;
    cursor: pointer;
    margin-top: 15px;
    text-align: center;
}

.cu-success .p {
    color: #ffffff;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin: 0;
    cursor: pointer;
}

.cu-success .group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 127px;
}

.cu-success .overlap-group {
    border-radius: 50px;
    height: 100px;
    padding-bottom: 16px;
}

.cu-success .fe-check {
    height: 100px;
    width: 100px;
}

.cu-success .ellipse {
    border: 1px solid;
    border-color: #00840d;
    border-radius: 50px;
    height: 100px;
    left: 0;
    top: 0;
    width: 100px;
}