.admin-verifcation-page {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .admin-verifcation-page .div {
    background-color: #ffffff;
    height: 736px;
    position: relative;
    width: 414px;
  }
  
  .admin-verifcation-page .rectangle {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 0px 0px 5px 5px;
    height: 29px;
    left: 0;
    position: absolute;
    top: 0;
    width: 414px;
  }
  
  .admin-verifcation-page .button-instance {
    position: absolute !important;
    top: 616px !important;
  }
  
  .admin-verifcation-page .component {
    height: 50px;
    left: 50px;
    position: absolute;
    top: 376px;
    width: 314px;
  }
  
  .admin-verifcation-page .overlap-group {
    border: 1.5px solid;
    border-color: #013d6f;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000014;
    height: 52px;
    left: -1px;
    position: relative;
    top: -1px;
    width: 316px;
  }
  
  .admin-verifcation-page .text-wrapper-2 {
    color: #000000;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 100px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 12px;
  }
  
  .admin-verifcation-page .frame {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    left: 73px;
    padding: 10px;
    position: absolute;
    top: 434px;
  }
  
  .admin-verifcation-page .p {
    color: #014886;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .admin-verifcation-page .fire-prevention {
    height: 219px;
    left: 51px;
    position: absolute;
    top: 63px;
    width: 312px;
  }
  
  .admin-verifcation-page .line {
    height: 1px;
    left: 72px;
    position: absolute;
    top: 588px;
    width: 269px;
  }
  
  .admin-verifcation-page .text-wrapper-3 {
    color: #01294c;
    direction: rtl;
    font-family: "Inter-Bold", Helvetica;
    font-size: 28px;
    font-weight: 700;
    left: 122px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 317px;
  }
  

  .admin-verifcation-page .input-wrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.admin-verifcation-page .input-wrapper input {
  width: 25px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  border: none;
  border-bottom: 1.5px solid #013d6f;
  border-radius: 0; 
  outline: none;
  transition: border-color 0.3s;
  background: transparent;  
  padding: 0;
}

.admin-verifcation-page .input-wrapper input:focus {
  border-color: #014886;
}




