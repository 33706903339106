.emergency {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.emergency .div {
  background-color: #ffffff;
  height: 736px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.sysAdmin_header {
  width: 100%;
  position: absolute;
  z-index: 2;
  background: #012F51;
}
.sysAdmin-rectangle2 {
  display: flex;
  flex-direction: row;
  margin: 0px auto 0px;
  width: 100%;
  height: 100%;

}
.sysAdmin-nav_container {
  height: 86px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    margin:  0 30px;
    justify-content: space-between;
    align-items:flex-end;
}
.sysAdmin-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100% - 86px;
  margin-top: 86px;
  /* position: static; */
}

/* .emergency .rectangle-2 {
  background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
  border-radius: 0px 0px 5px 5px;
  height: 29px;
  left: 0;
  position: absolute;
  top: 0;
  width: 414px;
} */

.emergency .group {
  height: 60px;
  /* left: 50px; */
  /* position: absolute; */
  /* top: 400px; */
  width: 316px;
  margin: auto;
  margin-bottom: 0;

}

.emergency .div-wrapper {
  border: none;
  background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
  color: #fff;
  border-radius: 5px;
  height: 60px;
  position: relative;
  width: 314px;
bottom: 0;
}
.sysAdmin-button {
  border: none;
  background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
  color: #fff;
  border-radius: 5px;
  height: 60px;
  width: 314px;
  line-height: normal;
  font-size: 22px;
  margin: auto;


}

.emergency .text-wrapper-2 {
  color: #fff;
  direction: rtl;
  font-family: "Inter-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 78px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
}

.emergency .emergency-overlap {
  height: 195px;
  /* left: 110px; */
  /* position: absolute; */
  /* top: 95px; */
  width: 195px;
  padding-bottom: 115px;
  padding-top: 115px;
  margin: 0 auto;
}
