.area-manager-container .area-manager-not-clicked {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    color: #ffffff;
    /* position: relative; */
    right: 160px;
    border: 0.1px solid white;
    cursor: pointer;
    width: 150px;
    justify-content: space-evenly;
    background-color: transparent;
    height: 37px;
    bottom: 0px;
}



.area-manager-container .can-approve {
    background-color: #012C55;
    color:white;
}


.area-manager-container .popup-header {
    background-color: #013D70;  
    height: 5px; 
    border-radius: 10px 10px 0 0;  
}


.area-manager-container .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
.area-manager-container  .popup-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    padding-bottom: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
    width: 75%;
    max-width: 414px;
    height: 20%;
    min-height: 200px;
}


.area-manager-container .popup-titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40px; 
    color: #013D70;
}

.area-manager-container .area-manager-container .popup-titles div {
    display: flex;
    flex-direction: column;
}

.area-manager-container .popup-titles h2, .popup-titles p {
    margin: 0;
    font-weight: normal;
}

.area-manager-container .popup-titles button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #013D70;
}

.area-manager-container .popup-left {
    flex: 1;
    display: flex;
    justify-content: center; 
    margin: auto;
    margin-bottom: 30px; 
    width: 100%;
}

.area-manager-container .popup-right {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 90%;
}

.area-manager-container .popup-container select {
    direction: rtl;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    height: 100%;
    margin: auto;
}


.area-manager-container .popup-right button {
    background-color: #013D70; 
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;

}

.area-manager-container .popup-container select:focus option:checked {
    background-color: #fff; 
    color: #000;
  }
  
.area-manager-container .popup-container select option:hover {
    background-color: #f5f5f5; 
}