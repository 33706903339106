

.event-details__map img[src$="#custom_marker"]{
    border: 2px solid #013D70 !important;
    border-radius:50%;
    object-fit: cover;
    width: 24px;
    height: 24px;
    object-position: center;
}

.event-details__map .info-window-avatar{
    object-fit: cover;
    width: 40px;
    height: 40px;
    object-position: center;
    margin-bottom: 10px;
}

