.panic {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    min-height: fit-content;
    position: relative;
}

.panic-header {
    width: 100%;
    position: absolute;
    z-index: 101;
    background: #012F51;

}

.panic-nav_container {
    height: 86px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    margin: 0 30px;
    justify-content: space-between;
    z-index: 2;
}

.panic-link_container {
    display: flex;
    height: 100%;
    width: 90px;
    align-items: flex-end;
    justify-content: space-between;

}

.panic-nav_link {
    background: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
}

.panic-rectangle1 {
    display: flex;
    /* height: 100%; */
    flex-direction: row;
    margin: 0px auto 0px;
    /* max-width: 1440px; */
    width: 100%;
    height: 79px;
    /* background: #012F51; */
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
}

.panic-admin-button {
    width: 40px;
    height: 40px;
    /* position: absolute; */
    top: 46px;
    left: 321px;
    z-index: 999;
}

.panic-usr-add {
    width: 45px;
    height: 45px;
    /* position: absolute; */
    top: 46px;
    left: 53px;
    z-index: 999;
}

.panic-admin-invite {
    /* position: absolute; */
    top: 50px;
    left: 120px;
    color: #fff;
    z-index: 2;
}

.panic-rectangle2 {
    width: 100%;
    height: 162px;
    background: #012F51;
    position: absolute;
    top: 70px;
    left: 0px;
    z-index: 0;
    border-radius: 0px 0px 5px 5px;
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
}

.panic-main {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.panic-button {
    z-index: 100;
    padding: 5px;
    position: relative;
}

.panic .inerContainer {
    margin-top: 30px;
    width: 100vw;
    height: 400px;
    display: grid;
    place-items: center;
}

.panicPulse {
    border-radius: 50%;
    border: #B70000 solid 5px;
    width: 220px;
    height: 220px;
    /* background-color: #B70000; */
    position: absolute;
    opacity: 0;
    z-index: 1;
    top: 1px;
    right: 1px;

    animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
}
.panicPulse:nth-child(1){animation-delay: 0s;}
.panicPulse:nth-child(2){animation-delay: 1s;}
.panicPulse:nth-child(3){animation-delay: 2s;}
.panicPulse:nth-child(4){animation-delay: 3s;}

@keyframes scaleIn {
    from {
        transform: scale(.5, .5);
        opacity: .5;
    }

    to {
        transform: scale(2, 2);
        opacity: 0;
    }
}

/* Vendor prefixes for keyframes */
@-webkit-keyframes scaleIn {
    from {
        -webkit-transform: scale(.5, .5);
        opacity: .5;
    }

    to {
        -webkit-transform: scale(2, 2);
        opacity: 0;
    }
}

@-moz-keyframes scaleIn {
    from {
        -moz-transform: scale(.5, .5);
        opacity: .5;
    }

    to {
        -moz-transform: scale(2, 2);
        opacity: 0;
    }
}

@-o-keyframes scaleIn {
    from {
        -o-transform: scale(.5, .5);
        opacity: .5;
    }

    to {
        -o-transform: scale(2, 2);
        opacity: 0;
    }
}

@-ms-keyframes scaleIn {
    from {
        -ms-transform: scale(.5, .5);
        opacity: .5;
    }

    to {
        -ms-transform: scale(2, 2);
        opacity: 0;
    }
}

.panic-circle {
    position: relative;
    width: 216px;
    height: 216px;
    background-color: #B70000;
    border-radius: 50%;
    border: solid floralwhite 5px;
    color: white;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    line-height: 1.5;
    z-index: 2;
}

.panic_location-container {
    position: relative;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panic-input-area {
    position: relative;
    z-index: 2;
    top: -60px;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 5px;
    font-size: 18px;
}
.panic-input-area .location-error-text{
    color: red;
}

.panic-input-wrapper {
    display: flex;
    flex-direction: row-reverse;
}

.panic-input-btn {
    width: 70px;
    border: none;
    border-radius: 5px 0 0 5px;
    background: linear-gradient(180deg, #012F51 0%, #014886 100%);
    color: #fff;
    font-size: 16px;
}

.panic-input {
    direction: rtl;
    width: 200px;
    height: 50px;
    flex-shrink: 0;
    color: #013D70;
    text-align: center;
    font-family: Rubik;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 0 5px 5px 0;
    border: 1.5px solid #013D70;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
}

.panic-gps-location {
    position: relative;
    top: -50px;
    display: flex;
    gap: 5px;
    width: 100%;
    direction: rtl;
    align-items: stretch;
    justify-content: center;
}

.panic-location-text {
    margin: 0;
    z-index: 2;
    background-color: white;
}

.panic-icon-location {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    fill: #012F51;
}

.panic-line {
    width: 289px;
    height: 0.px;
    flex-shrink: 0;
    stroke-width: 0.5px;
    stroke: #808080;
    position: relative;
    top: -20px;

}

.panic-image {
    width: 80px;
    margin: 0 auto 40px auto;
    padding-top: 20px;
}

.panic-rectangle3 {
    width: 414px;
    height: 29px;
    position: absolute;
    top: 707px;
    left: 0px;
    border-radius: 0px 0px 5px 5px;
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
}

.placeholder-text-element-class {
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0;
    text-align: center;
}