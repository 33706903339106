.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 216px;
    height: 216px;
    flex-shrink: 0;
    margin-top: 55px;
    border: 12px solid #f3f3f3;
    border-top: 13px solid #0959F4;
    border-radius: 54%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.smaller-loader {
    flex-shrink: 0;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #0959F4;
    border-radius: 54%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}