.logo-screen-wrapper{
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    position: fixed;
    z-index: 9999;
    background-color: white;
    text-align: center;
}
.logo-screen-wrapper .shomrey-logo{
    height: 150px;
    width: 150px;
    margin-top: 30vh;
}
.logo-screen-wrapper .logo-screen-spinner .loader{
    height: 60px;
    width: 60px;
}