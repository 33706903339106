.ca-one {
    background-color: #ffffff;
    /* width: 100%; */
    width: 100%;
    max-width: 100vw;
    height: 100vh;
}

.ca-one .div-2 {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    max-width: 400px;
    text-align: center;
    margin: auto;   
}

.ca-one .rectangle {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 0px 0px 5px 5px;
    height: 29px;
    width: 100%;
}
.ca-one .progress-one {
    position: relative;
    text-align: center;
    top: -30px;
}

.ca-one .group {
    height: 15px;
    left: 51px;
    /* position: absolute; */
    top: 71px;
    width: 313px;
}

.ca-one .overlap-group-2 {
    height: 15px;
    /* position: relative; */
}

.ca-one .line {
    height: 2px;
    left: 8px;
    /* position: absolute; */
    top: 6px;
    width: 143px;
}

.ca-one .img {
    height: 2px;
    left: 160px;
    /* position: absolute; */
    top: 6px;
    width: 141px;
}

.ca-one .ellipse {
    background-color: #013d70;
    border-radius: 7.5px;
    height: 15px;
    left: 298px;
    /* position: absolute; */
    top: 0;
    width: 15px;
}

.ca-one .ellipse-2 {
    background-color: #d9d9d9;
    border-radius: 7.5px;
    height: 15px;
    left: 149px;
    /* position: absolute; */
    top: 0;
    width: 15px;
}

.ca-one .ellipse-3 {
    background-color: #d9d9d9;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    /* position: absolute; */
    top: 0;
    width: 15px;
}

.ca-one .frame {
    background-color: #ffffff;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
}

.ca-one .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    /* position: relative; */
}

.ca-one .text-wrapper-2 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    /* position: relative; */
    text-align: left;
    width: fit-content;
    padding-bottom: 0px;
}

.ca-one .text-wrapper-3 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    /* position: relative; */
    text-align: left;
    width: fit-content;
}

.ca-one .frame-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 100px;
    height: 400px;
    width:90%;
    max-width:380px;
    padding-bottom: 15px;
    /* position: relative; */
}

.ca-one .frame-wrapper button {
    margin-top: auto;
}

.ca-one .frame-3 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}


/* .ca-one .component-22 {
    position: relative !important;
} */

.ca-one .component-instance {
    color: #000000 !important;
    direction: unset !important;
    left: 166px !important;
    text-align: right !important;
}

.ca-one .component-22-instance {
    left: 253px !important;
}

.ca-one .design-component-instance-node {
    left: 229px !important;
}

.ca-one .component-2 {
    direction: unset !important;
    left: 129px !important;
    text-align: right !important;
}

.ca-one .component-3 {
    left: 183px !important;
}

.ca-one .component-4 {
    left: 205px !important;
}

.ca-one .overlap-wrapper {
    height: 60px;
    margin-right: -2px;
    /* position: relative; */
    width: 316px;
}

.ca-one .overlap {
    border-radius: 5px;
    height: 60px;
    /* position: relative; */
    width: 314px;
}

.ca-one .rectangle-2 {
    background-color: #013d70;
    border-radius: 5px;
    height: 60px;
    left: 0;
    /* position: absolute; */
    top: 0;
    width: 314px;
}

.ca-one .text-wrapper-4 {
    color: #ffffff;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    left: 107px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: left;
    top: 14px;
    width: 100px;
}

.ca-one .steps-btn-container {
    position: absolute;
    top: 30px;
    width:100%;
    height: 50px;
    justify-content: space-between;
    display:flex;
    align-items: center;
}
.ca-one .steps-btn-container .box{
    margin-top: 40px;
    width: 33%;
}

@media screen and (max-width: 430px) {
    .ca-one .frame-wrapper {
        align-items: flex-start;
        display: inline-flex;
        flex-direction: column;
        gap: 0;
        height: unset;
    }
}

.error-message {
    width: 100%;
    text-align: center;
    color: red;
}