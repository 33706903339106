.modal {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  background-color: #fff;
}


.slide-up-modal-zIndex { 
  z-index: 1000;  
}

.slide-up-edit-modal-zIndex {
  z-index: 1001;  

}
  .modal.open {
    height: 100%;
    z-index: 3;
  }
  
  .modal-content {
    position: relative;
    height: 100%;
    overflow-y: auto; /* This line enables vertical scrolling */
  }
  
  .close-button {

    font-size: 40px;
    border: none;
    background: none;
    cursor: pointer;
    position: fix;
  }

  .details-x-button {
    z-index: 1002

  }

  .edit-x-button {
    z-index: 1005
  }


  