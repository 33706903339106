.cu-one {
    background-color: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.cu-one .div-2 {
    background-color: #ffffff;
    height: 719px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    text-align: center;
}
.cu-one .div-2 .select{
    text-align: start;
}

.cu-one .rectangle {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 0px 0px 5px 5px;
    height: 23px;
    width: 100%;
}

.cu-one .progress-one {
    position: relative;
    text-align: center;
    top: -30px;
}

.cu-one .frame {
    background: #fcfcfc;
    padding: 10px;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
    max-width: 400px;
}

.cu-one .error-message {
    color: red;
    font-size: 14px;
    font-weight: bold;
    /* position: relative;
    top: -20px;
    right: -80px;
    margin-bottom: -20px;
    padding-bottom: 15px; */
    text-align: center;
}

.cu-one .pass-message {
    color: green;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    top: -20px;
    right: -80px;
    margin-bottom: -20px;
    padding-bottom: 15px;
}

.cu-one .err-div {
    text-align: right;
    gap: 5px;
}


/* .cu-one .design-component-instance-node {
    position: relative !important;
}

.cu-one .component-22-instance {
    left: 222px !important;
}

.cu-one .component-2 {
    left: 199px !important;
}

.cu-one .component-3 {
    left: 203px !important;
}

.cu-one .component-4 {
    left: 146px !important;
} */

.cu-success .text-wrapper {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    box-shadow: none;
    border: none;
}

.cu-one .group {
    height: 15px;
    left: 51px;
    /* position: absolute; */
    top: 71px;
    width: 313px;
}

.cu-one .overlap {
    height: 15px;
    left: 0;
    /* position: absolute; */
    top: 0;
    width: 149px;
}

.cu-one .line {
    height: 1px;
    left: 8px;
    object-fit: cover;
    /* position: absolute; */
    top: 6px;
    width: 141px;
}

.cu-one .ellipse {
    background-color: #d9d9d9;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    /* position: absolute; */
    top: 0;
    width: 15px;
}

.cu-one .overlap-2 {
    height: 15px;
    left: 149px;
    /* position: absolute; */
    top: 0;
    width: 164px;
}

.cu-one .img {
    height: 1px;
    left: 11px;
    object-fit: cover;
    /* position: absolute; */
    top: 6px;
    width: 141px;
}

.cu-one .ellipse-2 {
    background-color: #013d70;
    border-radius: 7.5px;
    height: 15px;
    left: 149px;
    /* position: absolute; */
    top: 0;
    width: 15px;
}

.progress-one {
    padding: 42px 0px 16px 0px;
}

.dropdown {
    display: none;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    position: relative;
}

.dropdown.open {
    display: block;
}

.dropdown div {
    color: black;
    padding: 12px 0px;
    text-decoration: none;
    display: flex;
    text-align: right;
    width: 100%;
    flex-direction: row-reverse;
}

button.dropDown-button {
    color: #757575;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    top: 12px;
    overflow: hidden;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000014;
    width: 318px;
    height: 50px;
    background: #ffff;
    text-align: right;
    max-width: 100%;
}

.div-2 .text-wrapper-2 {
    color: rgba(1, 58, 104, 1);
    height: 39px;
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 32px;
}

@media screen and (max-width: 430px) {
    .dropDowne {
        width: 100%;
    }
    button.dropDown-button {
        width: 103%;
    }
}

.drop-down-container {
    width: 100%;
    box-sizing: border-box;
}

