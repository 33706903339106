  .button-approve-changes {
    width: 314px;
    max-width: 80vw;
    height: 60px;
    top: 584px;
    left: 50px;
    text-align: center;
    margin: auto;
    margin-top: 50px;
    color: white;
    border: 1px solid black;
    border-radius: 10px;
    background: linear-gradient(#013D70, #014886);
  }

  .button-approve-changes > p {
    color: #FFF;
    direction: rtl;
    /* font-family: Inter; */
    /* font-size: 22px; */
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
  }
  

  