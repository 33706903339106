.id-photo-modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-height: fit-content;
    background-color: white;
    text-align: center;
    direction: rtl;
}
.id-photo-modal-container .rectangle{
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 0px 0px 5px 5px;
    height: 23px;
    left: 0;
    top: 0;
    width: 100%;
}
.id-photo-modal-container .close-btn-wrapper{
    text-align: start;
    padding: 10px;
}
.id-photo-modal-container .title{
    font-size: 24px;
    padding: 0 10px;
}
.id-photo-modal-container .id-photo-btns-wrapper{
    padding: 10px;
}
.id-photo-modal-container h3{
    margin: 5px auto;
}
.id-photo-modal-container .id-photo-display-wrapper{
    width: 200px;
    height: 80px;
    margin: auto;
    margin-top: 20px;
}
.id-photo-modal-container .id-photo-display-wrapper img{
    max-height: 120px;
    object-fit: contain;
}
.id-photo-modal-container .continue-btn-wrapper{


    margin-top: 80px;
    width: 100%;
    /* left: 10vw; */
}
video {
    width: 100%;
    max-width: 100%;
    height: auto;
}
.id-photo-modal-container .takeApick__container{

    margin-top: 2rem;
    /* margin-bottom: 2rem; */
    text-align: center;
    height: auto;
}
.id-photo-modal-container .takeApick__container canvas{
    padding-bottom: 1rem;
    height: 50%;
    max-height: 112.5px;
}
.id-photo-modal-container .popup-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: #4a4a4a;
}
.id-photo-modal-container .popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 80%;
    z-index: 100;
}