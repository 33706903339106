/* Avatar.css */
.avatar-container {
    top: 40px;
    position: relative;
    border: 6px solid white;
    border-radius: 50%;
    height: 100%;
    aspect-ratio: 1/1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
    font-size: 20px;
    font-weight: 500;
}

.avatar-image {
    border-radius: 50%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
}