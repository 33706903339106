.component .overlap-group {
    /* border: 1.5px solid; */
    border-color: #cdcdcd;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000014;
    height: 52px;
    left: -1px;
    top: -1px;
    width: 316px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Ensure the div takes up full height */
}

.component .div-wrapper {
    position: relative;
    width: 100%;
}

.component .pass-visibility {
    position: absolute;
    left: 3%;
    top: 50%;
    transform: translateY(-50%);
    /* opacity: 0; */
    cursor: pointer;
    color: rgb(197, 196, 196);
    background: none;
    border: none;
    padding: 0;
}

/* .component .pass-visibility:hover {
    opacity: 1;
} */

.text-wrapper {
    color: #013d70;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    top: 12px;
    overflow: hidden;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000014;
    height: 50px;
    width: 314px;
    margin: 0;
    padding: 0;
}

.component .valText {
    color: #dc0000;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 124px;
    letter-spacing: 0;
    line-height: normal;
    position: fixed;
    top: 53px;
    display: contents;
}


/* 
.component .div-wrapper {
    border: 1.5px solid;
    border-color: #cdcdcd;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000014;
    height: 52px;
    left: -1px;
    position: relative;
    top: -1px;
    width: 316px;
} */

.component.default {
    height: 71px;
    position: relative;
}

.component.variant-2 {
    height: 50px;
}

@media screen and (max-width: 600px) {
    .text-wrapper {
        width: 100%;
    }
}