.private-details-container {
    align-items: flex-end;
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* padding-right: '10px';
    padding-left: '10px'; */
    position: relative;
    padding-left: 15px;
}

.private-details-container .div {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
}
.admin-pannel-container .private-details-container .div {
    direction: ltr;
}
.admin-pannel-container .private-details-container .text-wrapper-2 {
    font-size: 20px;
    font-weight: 600;
}

.private-details-container .p {
    color: #303030;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: left;
    width: 134px;
}

.private-details-container .text-wrapper {
    font-weight: 500;
}
.private-details-container .text-title-wrapper {
    font-weight: 700;
    font-size: 17px;
}

.private-details-container .span {
    color: #303030;
    font-family: "Rubik", Helvetica;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0;
    word-break: break-all;
}

.private-details-container .text-wrapper-2 {
    color: #303030;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.private-details-container .text-wrapper-3 {
    color: #013a68;
    font-family: "Rubik", Helvetica;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: right;
    text-decoration: underline;
    white-space: nowrap;
    width: fit-content;
}

.private-details-container .element {
    color: #303030;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
}

.private-details-container .text-wrapper-4 {
    color: #303030;
    font-family: "Rubik", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
}

  .user-details-id-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;;
  }

