.invite-admin_approve-title {
  color: #013d70;
  direction: rtl;
  font-family: "Inter-Regular", Helvetica;
  font-size: 16px;
  margin-bottom: 15px;
}

.invite-admin_number {
  color: #013d70;
  font-family: "Inter-SemiBold", Helvetica;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.invite-admin_fix-number {
  color: #013d70;
  direction: rtl;
  font-family: "Inter-Regular", Helvetica;
  font-size: 22px;
  margin: 33px 0 8px;
}