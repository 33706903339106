.location {
  height: 23px;
  left: 180px;
  position: absolute;
  top: 70px !important;
  width: 176px !important;
  display: flex;
  gap: 5px;
  align-items: center;
}

.location__address {
  color: #012f51;
  direction: rtl;
  font-family: "Rubik-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  /* left: 0; */
  letter-spacing: 0;
  line-height: normal;
  /* position: absolute; */
  text-align: center;
  /* top: 0; */
  white-space: nowrap;
  width: 146px;
}

.location__icon {
  height: 22px;
  /* left: 152px;
  position: absolute;
  top: 1px; */
  width: 22px;
}