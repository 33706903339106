.admin-home {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: fit-content;
}

.admin-home .div-3 {
    background-color: #ffffff;
    width: 100%;
}

.admin-home .overlap {
    background-color: #012c55;
    border-radius: 0px 0px 5px 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.admin-home .user-waitings-warpper{
    width: 100%;
    max-width:95vw;
}
.admin-home .user-waitings-warpper .overlap {
    display: none;
}
.admin-home .user-waitings-warpper .items-container {
    flex-direction: column;
}
.admin-home .user-waitings-warpper .div-wrapper {
    height: 50px;
}
.admin-home .user-waitings-warpper .active-guards {
    margin-bottom: 0;
    padding-bottom: 0;
}
.admin-home .user-waitings-warpper .frame-4 {
    flex-direction: row;
}
.admin-home .user-waitings-warpper .frame-5 {
    height: 50vh; 
    overflow-y: auto;
    padding-bottom: 80px;
}

.admin-home .mdi-users {
    height: 40px;
    left: 35px;
    top: 96px;
    width: 40px;
}

.admin-home .text-wrapper-9 {
    color: #ffffff;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    user-select: none;
}

.admin-home .text-wrapper-10 {
    color: #ffffff;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
}

.admin-home .frame-4 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
}

.admin-home .group {
    height: 100%;
    width: 100%;
}

.admin-home .text-wrapper-11 {
    color: #012c55;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 194px;
    letter-spacing: 0;
    line-height: normal;
    top: 0;
    white-space: nowrap;
}

.admin-home .frame-5 {
    align-items: stretch;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-bottom: 30px;
    margin-bottom: 200px;
}

.admin-home .icons-container {
    display: flex;
    gap: 5px;
}

.admin-home .action-icon {
    fill: white;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s;
}

.admin-home .action-icon:hover {
    opacity: 0.6;
}

.admin-home .swipe-to-delete-2 {
    align-self: stretch;
    width: 100%;
    border: unset;
    box-shadow: unset;
    background-color: #ff0000;
    border-radius: 50px;
}

.admin-home .swipe-to-delete-3 {
    color: #d07d00;
    text-align: center;
    border: unset;
    box-shadow: unset;
}

.admin-home .swipe-to-delete-4 {
    background-color: #A1A1A1;
    text-align: center;
    border: unset;
    box-shadow: unset;
    border-radius: 50px;
}

.admin-home .swipe-to-delete-5 {
    background-color: #a0a0a0;
    text-align: center;
    border: unset;
    box-shadow: unset;
}

.admin-home .group-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 70px;
}

.admin-home .text-wrapper-12 {
    color: #012c55;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 195px;
    letter-spacing: 0;
    line-height: normal;
    top: 0;
    white-space: nowrap;
}

.admin-home .delete-icon-3 {
    height: 30px;
    left: 319px;
    top: 34px;
    width: 27px;
    z-index: 0;
}

.admin-home .nav-bar-instance {
    left: 0;
    position: fixed;
    top: 656px;
}

.admin-home .nav-bar-2 {
    height: 47.33px;
}

.admin-home .nav-bar-3 {
    height: 21px;
    left: 11px;
    top: 0;
    width: 21px;
}

.redBg {
    color: #ff0000;
}

.greenBg {
    color: #00840d;
}

.orabgeBg {
    color: #d07d00;
}

.admin-invite {
    color: white;
}

.pagination-controls {
    position: fixed;
    bottom: 80px;
    z-index: 2;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 10px auto;
    padding-bottom: 30px;
    padding-top: 10px;
    width: 100%;
    font-family: "Rubik", Helvetica;
    color: #012c55;
}

.pagination-controls button {
    background-color: #A1A1A1;
    color: white;
    border: none;
    padding: 5px 15px;
    margin: 0 10px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination-controls button:hover {
    background-color: #012c55;
}

.pagination-controls button:disabled {
    background-color: #D0D0D0;
    cursor: not-allowed;
}

.pagination-controls span {
    font-size: 18px;
    font-weight: 400;
}
