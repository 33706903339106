.cancel-event {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.cancel-event__wrapper {
  background-color: #ffffff;
  height: 736px;
  position: relative;
  width: 414px;
}

.cancel-event__button-active {
  background: linear-gradient(180deg, rgb(1,72,134) 0%, rgb(1,61,112) 100%);
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 4px #0000001a;
  height: 60px;
  position: relative;
  width: 314px;
  font-family: "Inter-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
}

.cancel-event__button-active-1 {
  left: 50px !important;
  position: absolute !important;
  top: 444px !important;
  color: #fff;
}

.cancel-event__button-active-2 {
  left: 50px !important;
  position: absolute !important;
  top: 520px !important;
  background: #fff !important;
  color: #DD0000;
  border: 1px solid #DD0000 !important;
}

.cancel-event__text {
  color: #013967;
  direction: rtl;
  font-family: "Rubik-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 76px;
  letter-spacing: 0;
  line-height: 26.8px;
  position: absolute;
  text-align: center;
  top: 292px;
  width: 262px;
}

.cancel-event__alert-header {
  left: 156px;
  position: absolute;
  top: 140px;
  width: 101px;
  padding-top: 3px;
}

.cancel-event__alert-wrapper {
  border: 1px solid #A19E9E;
  border-radius: 50%;
  animation: scale 1s linear infinite;
  width: 101px;
  height: 101px;
  left: 156px;
  position: absolute;
  top: 140px;
}

@keyframes scale {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}
