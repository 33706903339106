.guard-list-item {
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    justify-content: end;
    gap: 16px;
    padding: 4px;
    width: 95vw;
    max-width: 414px;
}

.guard-list-item .ant-design-setting{
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.guard-list-item .ant-design-setting button{
    border: none;
    color: white;
    height: 40px;
    background-color: #014886;
    border-radius: 10%;
}

.guard-list-item .polygon {
    height: 10.5px;
    width: 12.12px;
}

.guard-list-item .text-wrapper {
    color: #222222;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 16px;
    width: -moz-fit-content;
    border: unset;
    box-shadow: unset;
    height: unset;
}

.guard-list-item .text {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
}

.guard-list-item .div {
    align-self: stretch;
    color: #222222;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
}

.guard-list-item .p {
    align-self: stretch;
    color: #444444;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    margin: unset;
}

.guard-list-item .span {
    font-weight: 500;
}

.guard-list-item .text-wrapper-2 {
    color: #444444;
    font-family: "Rubik", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
}

.guard-list-item .avatar {
    height: 60px;
    object-fit: cover;
    width: 60px;
    justify-self: start;
    min-width: 60px;
}