.verifcation-page {
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
}

.verifcation-page .div {
  background-color: #ffffff;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding-top: 30px;
  gap: 10px;
}

.verifcation-page .rectangle {
  background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
  border-radius: 0px 0px 5px 5px;
  height: 29px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.verifcation-page .button-instance {
  left: unset;
  left: 50%;
}

.verifcation-page .code {
  height: 50px;
  width: 314px;
}

.verifcation-page .overlap-group {
  border: 1.5px solid;
  border-color: #013d6f;
  border-radius: 5px;
  box-shadow: 0px 2px 10px #00000014;
  height: 52px;
  width: 316px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verifcation-page .text-wrapper-2 {
  color: #000000;
  font-family: "Inter-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-align: right;
}

.verifcation-page .frame-22 {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 10px;
}

.verifcation-page .code-p {
  color: #014886;
  direction: rtl;
  font-family: "Inter-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
}

.verifcation-page .fire-prevention {
  height: 219px;
  width: 312px;
}

.verifcation-page .line {
  height: 1px;
  width: 269px;
}

.verifcation-page .text-wrapper-3 {
  color: #01294c;
  direction: rtl;
  font-family: "Inter-Bold", Helvetica;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin: 10px 0 10px 0;
}


.verifcation-page .input-wrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.verifcation-page .input-wrapper input {
  width: 25px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  border: none;
  border-bottom: 1.5px solid #013d6f;
  border-radius: 0;
  outline: none;
  transition: border-color 0.3s;
  background: transparent;
  padding: 0;
}

.verifcation-page .input-wrapper input:focus {
  border-color: #014886;
}


