.cu-two {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin: auto;
    width: 100%;
    max-width: 414px;
}

.cu-two .div {
    background-color: #ffffff;
    height: 719px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.cu-two .rectangle {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 0px 0px 5px 5px;
    height: 23px;
    left: 0;
    top: 0;
    width: 100%;
}

.cu-two .group {
    gap: 24px;
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    /*padding-buttom might break the button's height*/
    padding-bottom: 160px;
}

.cu-two .component {
    height: 75px;
    left: 0;
    top: 0;
    width: 316px;
}

.cu-two .overlap-group {
    border: 1.5px solid;
    border-color: #013d70;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000014;
    height: 76px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;
}

.cu-two .text-wrapper-2 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 81px;
    letter-spacing: 0;
    /* line-height: normal; */
    top: 24px;
    padding: 0;
}

.cu-two .progress-one {
    position: relative;
    text-align: center;
    top: -30px;
}

.cu-two .steps-btn-container {
    position: absolute;
    top: 30px;
    width:100%;
    height: 50px;
    justify-content: space-between;
    display:flex;
    align-items: center;
}
.cu-two .steps-btn-container .box{
    /* margin-top: 40px; */
    width: 33%;
    height: 50px;
}

.cu-two .solar-upload-bold {
    height: 24px;
    left: 266px;
    top: 24px;
    width: 24px;
}

.cu-two .overlap-wrapper {
    height: 75px;
    left: 0;
    top: 99px;
    width: 316px;
}

.cu-two .overlap {
    border: 1.5px solid;
    border-color: #013a68;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000014;
    height: 76px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
}

.cu-two .text-wrapper-3 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 118px;
    letter-spacing: 0;
    line-height: normal;
    top: 24px;
}

.cu-two .text-wrapper-4 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    left: 155px;
    letter-spacing: 0;
    line-height: normal;
    text-align: left;
    top: 101px;
}

.cu-two .group-2 {
    height: 15px;
    left: 50px;
    top: 71px;
    width: 313px;
}

.cu-two .overlap-2 {
    height: 15px;
    left: 0;
    top: 0;
    width: 149px;
}

.cu-two .line {
    height: 1px;
    left: 8px;
    object-fit: cover;
    top: 6px;
    width: 141px;
}

.cu-two .ellipse {
    background-color: #d9d9d9;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    top: 0;
    width: 15px;
}

.cu-two .overlap-3 {
    height: 15px;
    left: 149px;
    top: 0;
    width: 164px;
}

.cu-two .img {
    height: 1px;
    left: 11px;
    object-fit: cover;
    top: 6px;
    width: 141px;
}

.cu-two .ellipse-2 {
    background-color: #00840d;
    border-radius: 7.5px;
    height: 15px;
    left: 149px;
    top: 0;
    width: 15px;
}

.cu-two .ellipse-3 {
    background-color: #013d70;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    top: 0;
    width: 15px;
}

.container {
    width: 316px;
    height: 75px;
    text-align: center;
}


/* CAMERA  */

video {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.takeApick__container {
    margin-top: 2rem;
    /* margin-bottom: 2rem; */
    text-align: center;
    height: auto;
}

.takeApick__container canvas {
    padding-bottom: 1rem;
    height: 50%;
    max-height: 112.5px;
}

.group .popup-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #4a4a4a;
}
.group .popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 80%;
}

.button-container {
    display: flex;
    width: 100%;
}

.darker {
    background: #4a4a4a !important;
}

.uploaded-file-border {
    border-color: green !important;
}
.uploaded-file-title {
    color: green !important;
}

.step-two-user-hasPhoto-container {
    display: flex;
    align-items: center;
    width: fit-content;
    justify-content: space-evenly;
    height: 50% !important;
    margin-right: 1em !important;

}

.step-two-user-hasPhoto-green {
    color: green;
}


.step-two-user-hasPhoto-container > div {
    padding-right: 0.3em;
}

.step-two-user-hasPhoto-container > p {
    padding-left: 0.3em;
    padding-right: 0.3em;
}

.group > p {
    direction: rtl;
    text-align: center;
    color: red;
}


.massage-sent-container > h2 {
    text-align: center !important;
}

