.popup-overlay {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.popup-header h3 {
  margin: 10px;
}

.popup-close {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-left: 30px;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-button-blue {
  background-color: #183f6a;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 28px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: bolder;
}
.popup-button-red {
  background-color: #ffffff;
  border: 2px solid red;
  color: red;
  border-radius: 5px;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: bolder;
}

.popup-button:hover {
  background-color: #0069d9;
}

