.oe-step-two {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.oe-step-two .overlap-wrapper {
  background-color: #ffffff;
  height: 736px;
  width: 414px;
  position: relative;
}

.oe-step-two .rectangle {
  background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
  border-radius: 0px 0px 5px 5px;
  height: 29px;
  left: 0;
  position: absolute;
  top: 707px;
  transform: rotate(-180deg);
  width: 414px;
}

.oe-step-two .overlap-group-wrapper {
  height: 60px;
  left: 50px;
  position: absolute;
  top: 606px;
  width: 316px;
} 

.oe-step-two .overlap-group {
  border: 1px solid;
  border-color: #b90000;
  border-radius: 5px;
  height: 60px;
  position: relative;
  width: 314px;
  background-color: #fff;
  cursor: pointer;
}

.oe-step-two .div {
  color: #b90000;
  direction: rtl;
  font-family: "Inter-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 78px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
}

.oe-step-two .text-wrapper-2 {
  color: #013967;
  direction: rtl;
  font-family: "Rubik-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 138px;
  letter-spacing: 0;
  line-height: 26.8px;
  position: absolute;
  text-align: center;
  top: 335px;
  white-space: nowrap;
}

.oe-step-two .text-wrapper-3 {
  color: #dc0000;
  direction: rtl;
  font-family: "Rubik-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 103px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 382px;
  white-space: nowrap;
}

.oe-step-two .group-2 {
  height: 42px;
  left: 264px;
  position: absolute;
  top: 422px;
  width: 60px;
}

.oe-step-two .text-wrapper-5 {
  color: #01335a;
  direction: rtl;
  font-family: "Rubik-Regular", Helvetica;
  font-size: 18px;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  top: 30px;
  white-space: nowrap;
  font-weight: 600;
  cursor: pointer;
}

.oe-step-two .group-3 {
  height: 42px;
  left: 158px;
  position: absolute;
  top: 422px;
  width: 60px;
}

.oe-step-two .text-wrapper-6 {
  color: #01335a;
  direction: rtl;
  font-family: "Rubik-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  top: 0;
  cursor: pointer;
}

.oe-step-two .group-4 {
  height: 42px;
  left: 50px;
  position: absolute;
  top: 422px;
  width: 60px;
  background-color: #fff;
  border: none;
}

.oe-step-two .amount-button {
  background-color: #ffffff;
  border: 0.5px solid;
  border-color: #a70000;
  border-radius: 10px;
  box-shadow: 0px 4px 4px #0000001a;
  height: 71px;
  width: 99px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
}

.oe-step-two .emergency-address {
  height: 23px;
  left: 180px;
  position: absolute;
  top: 70px !important;
  width: 176px !important;
}

.oe-step-two .text-wrapper-0 {
  color: #012f51;
  direction: rtl;
  font-family: "Rubik-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 146px;
}

.oe-step-two .octicon-location {
  height: 22px;
  left: 152px;
  position: absolute;
  top: 0;
  width: 22px;
}

.oe-step-two .alert-header {
  left: 120px;
  position: absolute;
  top: 140px;
  padding-top: 5px;
}

.oe-step-two .alert-wrapper {
  border: 1px solid #dd0000;
  border-radius: 50%;
  animation: scale 1s linear infinite;
  width: 178px !important;
  height: 177px !important;
  left: 120px;
  position: absolute;
  top: 140px;
}

@keyframes scale {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}