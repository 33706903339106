.send-code {
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
}

.send-code .div {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-inline: auto;
    height: 100%;
}

.send-code .rectangle-2 {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 0px 0px 5px 5px;
    height: 29px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}


.send-code .fire-prevention {
    height: 219px;
    margin-top: 30px;
    width: 312px;
}

.send-code .group {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 42px;
}

.send-code .text-wrapper-2 {
    color: #01294c;
    direction: rtl;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.send-code .p {
    color: #012c52;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    height: normal;
    top: 42px;
    width: 314px;
}

.send-code .text-wrapper-3 {
    color: #01294c;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    padding-top: 40px;
    padding-bottom: 16px;
    margin-left: 140px;
}

.send-code .component {
    height: 50px;
    left: 0;
    top: 38px;
    width: 314px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.send-code .component input {
    padding-inline-start: 6px;
}

.send-code .overlap-group {
    border: 1.5px solid;
    border-color: #cdcdcd;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000014;
    height: 52px;
    left: -1px;
    top: -1px;
    width: 316px;
}

.send-code .text-wrapper-4 {
    color: #333333;
    font-family: "Inter-Regular", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 156px;
    letter-spacing: 0;
    -height: normal;
    top: 11px;
    white-space: nowrap;
}

.send-code .overlap {
    background: ar-gradient(180deg, rgb(1.93, 121.98, 28.34) 0%, rgb(0, 160.22, 16.02) 100%);
    border-radius: 5px 0px 0px 5px;
    height: 50px;
    left: 1px;
    top: 1px;
    width: 105px;
}

.send-code .fe-check {
    height: 24px;
    left: 41px;
    top: 13px;
    width: 24px;
}

.send-code .line-button {
    height: 1px;
    width: 269px;
    color: black;
    margin-top: 42px;
    margin-bottom: 28px;
    background-color: black;
}

.send-code .btn-container {
    width: 80%;
}

.disabled {
    background-color: rgb(194 194 194) !important;
}