.screen-two {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .screen-two .div {
    background-color: #ffffff;
    height: 291px;
    position: relative;
    width: 375px;
  }
  
  .screen-two .text-wrapper {
    color: #013d70;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 28px;
    font-weight: 600;
    left: 86px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 109px;
  }
  
  .screen-two .group {
    height: 50px;
    left: 30px;
    position: absolute;
    top: 205px;
    width: 316px;
  }
  
  .screen-two .overlap-group {
    background-color: #013d70;
    border-radius: 5px;
    height: 50px;
    position: relative;
    width: 314px;
  }
  
  .screen-two .text-wrapper-2 {
    color: #ffffff;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    left: 130px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: left;
    top: 11px;
  }
  
  .screen-two .rectangle {
    background-color: #013d70;
    height: 10px;
    left: 0;
    position: absolute;
    top: 0;
    width: 375px;
  }
  
  .screen-two .p {
    color: #013d70;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 81px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 66px;
    white-space: nowrap;
  }
  