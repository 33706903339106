.oe-step-three {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.oe-step-three__wrapper {
  background-color: #ffffff;
  height: 800px;
  position: relative;
  width: 414px;
}

.oe-step-three__title {
  margin: 0;
  color: #013967;
  direction: rtl;
  font-family: "Rubik-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 156px;
  letter-spacing: 0;
  line-height: 26.8px;
  position: absolute;
  text-align: center;
  top: 263px;
  white-space: nowrap;
}

.oe-step-three__pulse {
  border: 1px solid #dd0000;
  border-radius: 50%;
  animation: scale 1s linear infinite;
  width: 101px;
  height: 101px;
  left: 156px;
  position: absolute;
  top: 140px;
}

.event-loading .event-loading-text{
  font-size:24px; 
  font-weight:700; 
  color: #013967;
  direction:rtl; 
  text-align:center;
}
.event-loading .loader{
  border-top-color: #b90000;
}

.oe-step-three__alert-header {
  left: 156px;
  position: absolute;
  top: 140px;
  width: 101px;
  padding-top: 5px;
}

.oe-step-three__subtitle {
  color: #00840d;
  direction: rtl;
  font-family: "Rubik-Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  left: 123px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 383px;
  white-space: nowrap;
}

.oe-step-three__time {
  color: #014886;
  font-family: "Rubik-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 172px;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 429px;
  white-space: nowrap;
}

.oe-step-three__buttons-wrapper {
  /* height: 60px; */
  left: 50px;
  position: absolute;
  top: 550px;
  width: 316px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.oe-step-three__button {
  border: 1px solid;
  border-color: #b90000;
  border-radius: 5px;
  height: 60px;
  position: relative;
  width: 314px;
  background: #fff;
  color: #b90000;
  direction: rtl;
  font-family: "Inter-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
}

.oe-step-three__button_home {
  border-color: #014886;
  color: #fff;
  background-color: #014886;
}
.oe-step-three__button_details {
  border-color: #014886;
  color: #014886;
}

/* .oe-step-three .text-wrapper-2 {
  
  left: 78px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 15px;
} */

@keyframes scale {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}