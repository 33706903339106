.active-guard-card{
    display: flex;
    flex-direction: row-reverse;
    height: fit-content;
}
.active-guard-card .avatar-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: auto 5px;
    margin-left: 15px;
    border-radius: 50%;
    border: 5px solid black;
    position: relative;
}
.active-guard-card .avatar-img.is-commander{
    border-color:rgb(43, 188, 43);
}
.active-guard-card .avatar-img.is-guardian{
    border-color:#137cd8;
}
.active-guard-card .avatar-img.is-user{
    border-color:#d81613;
}
.active-guard-card .avatar-img img{
    width: 100%;
    height: 100%;
    width: 40px;
    height: 40px;
    /* margin: auto 5px; */
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}
.active-guard-card .avatar-img .weapon-icon{
    width: 30px;
    height: 30px;
    position: absolute;
    top: -10px;
    left: -17px;
    border: none;
}
.active-guard-card .guard-details{
    color: #013D70;
    font-size: 18px;
    font-weight: 600;
    /* height: fit-content; */
    width: 70%;
    /* max-width: 100%; */
    /* word-break: break-word; */
    text-align: end;
}
.active-guard-card .guard-details .guard-name{
    color: #013D70;
    font-size: 18px;
    font-weight: 600;
    word-break: break-word;
    text-align: end;
}
.active-guard-card .guard-details .distance{
    color: #6C6C6C;
    font-size: 15px;
    font-weight: 400;
    text-align: end;
}
.active-guard-card .guard-action-btns{
    width: 100%;
    justify-content: start;
    margin: auto;
    display: flex;
    flex-direction: row;
}
.active-guard-card .guard-action-btns .phone-btn{
    color: #013D70;
    /* border-radius: 50%; */
    width: 30px;
    height: 30px;
    padding: 10px;
}
.active-guard-card .guard-action-btns .walky-btn{
    background-color: #013D70;
    border-radius: 50%;
    /* border-radius: 50%; */
    width: 30px;
    height: 30px;
    padding: 10px;
}
.active-guard-card .guard-action-btns .walky-btn img{
    /* border-radius: 50%; */
    width: 30px;
    height: 30px;
    margin: auto;
}
.active-guard-card hr{
    color:#6C6C6C;
}