/* .admin-pannel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
} */


.read-edit-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
    /* width: 90vw; */
}

@media screen and (max-height: 700px) {
    .read-edit-container {
        padding-bottom: 900px;
        /* width: 90vw; */
    }
  }

.overlap {
    background-color: #012c55;
    border-radius: 0px 0px 5px 5px;
    height: 151px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.read-edit-container .overlap{
    height: 80px;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 3000;
    flex-direction: row;
    align-items: center;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 0 20px;
    box-sizing: border-box;
}
.read-edit-container .read-edit-form-wrapper{
    position: relative;
    top: 120px;
    height: fit-content;
    min-height: 80vh;
    padding: auto;
    padding-bottom: 10vh;
}

.modal.edit-modal.slide-up-edit-modal-zIndex .close-button {
    position: fixed;
    z-index: 9000;
    top: 10px;
    left: 5px;
    align-self: flex-start;
    cursor: pointer;
    font-size: 50px;
    font-weight: bold;
    color: white;
  }

.scrolArea {
    z-index: 1;
    padding: 0;
    margin: 0; 
}

.read-edit-container .details-section{
    text-align: center;
} 
.read-edit-container .main{
    width: 100%;
    margin-top: 100px;
} 
.read-edit-container .section-header{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0px 20px 0px 20px;
}
.admin-panel-conatianer .scrolArea{
    width: 100vh;
}
.read-edit-container .section-header .h2{
    color: #012C55;
    font-weight: 700;
    font-size: 24px;
    line-height: 28.44px;
}
.read-edit-container .error-message{
    text-align: center;
    height: 18px;
    color: red;
    margin: auto;
} 