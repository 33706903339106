.invite-admin_close {
  height: 20px;
  position: absolute;
  top: 41px;
  left: 30px;
  width: 20px;
}

.invite-admin_title {
  color: #013d70;
  direction: rtl;
  font-family: "Inter-SemiBold", Helvetica;
  font-size: 28px;
  font-weight: 600;
  position: absolute;
  left: 130px;
  top: 33px;
  margin: 0;
}

.invite-admin_subtitle {
  color: #013d70;
  direction: rtl;
  font-family: "Inter-SemiBold", Helvetica;
  font-size: 16px;
  position: absolute;
  left: 60px;
  top: 71px;
  margin: 0;
}

.invite-admin_form {
  position: absolute;
  left: 30px;
  top: 113px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.invite-admin_input {
  height: 50px;
  width: 290px;
  direction: rtl;
  border: 1.5px solid #cdcdcd;
  border-radius: 5px;
  box-shadow: 0px 2px 10px #00000014;
  padding-right: 20px;
  font-size: 18px;
  color: #013d70;
}

.invite-admin_input::placeholder {
  font-size: 18px;
  color: #013d70;
}

