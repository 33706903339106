.read-edit-form-wrapper .shiftAvailability-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    position: relative;
}
.read-edit-form-wrapper .shiftAvailability-wrapper input{
    width: 20px;
    height: 20px;
}
.read-edit-form-wrapper .shiftAvailability-wrapper label{
    font-weight: 700;
    font-size: 20px;
    padding-right: 5px;
}
.read-edit-form-wrapper .shiftAvailability-wrapper .unavailable{
    padding-left: 40px;
}
.read-edit-form-wrapper .shiftAvailability-wrapper .shiftAvailability-inputs-wrapper{
    display: flex;
}
.read-edit-form-wrapper .shiftAvailability-wrapper .shiftAvailability-input{
    padding-top: 5px;
}