.new-member {
    align-items: center;
    border-radius: 5px;
    display: flex;
    gap: 16px;
}

.new-member .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative;
}

.new-member .rectangle {
    background-color: #013a68;
    border-radius: 3px;
    height: 32px;
    position: relative;
    width: 60px;
}

.new-member .rectangle-2 {
    border: 1px solid;
    border-color: #013a68;
    border-radius: 3px;
    height: 32px;
    position: relative;
    width: 60px;
}

.new-member .text-wrapper-2 {
    color: #f5f5f5;
    direction: rtl;
    font-family: "DM Sans", Helvetica;
    font-size: 15px;
    font-weight: 400;
    left: 10px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: 6px;
    white-space: nowrap;
}

.new-member .text-wrapper-3 {
    color: #013a68;
    direction: rtl;
    font-family: "DM Sans", Helvetica;
    font-size: 15px;
    font-weight: 400;
    left: 7px;
    letter-spacing: 0;
    line-height: 18px;
    position: absolute;
    top: 42px;
    white-space: nowrap;
}

.new-member .text-2 {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 0px 15px 0px 0px;
}

.new-member .text-wrapper-4 {
    align-self: stretch;
    color: #222222;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
}

.new-member .div-2 {
    align-self: stretch;
    color: #444444;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    margin: 0;
}

.new-member .span {
    font-weight: 500;
}

.new-member .text-wrapper-5 {
    color: #444444;
    font-family: "Rubik", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
}

.new-member .avatar {
    height: 60px;
    object-fit: cover;
    position: relative;
    width: 60px;
}