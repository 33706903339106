.screen-one {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
  }
  
  .screen-one .div-2 {
    background-color: #ffffff;
    height: 291px;
    width: 90%; /* Adjusted width for flexibility */
  }
  
  .screen-one .component-22 {
    left: 30px !important;
    top: 115px !important;
  }
  
  .screen-one .text-wrapper-2 {
    color: #013d70;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 28px;
    font-weight: 600;
    left: 193px;
    line-height: normal;
    position: absolute;
    top: 33px;
  }
  
  .screen-one .p {
    color: #013d70;
    font-family: "Inter-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 54px;
    line-height: normal;
    position: absolute;
    top: 71px;
    white-space: nowrap;
  }
  
  .screen-one .group {
    height: 50px;
    left: 30px;
    position: absolute;
    top: 205px;
    width: 90%; /* Adjusted width for flexibility */
  }
  
  .screen-one .rectangle {
    background-color: #013d70;
    height: 10px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; /* Adjusted width for flexibility */
  }
  