.id-photo-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: black;
  border: 1px solid black;
  z-index: 2;
  box-sizing: border-box;
}

.img-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img-container img {
  height: 50vh;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.close-button {
  z-index: 2;
  position: absolute;
  top: -40px;
  left: 5px;
  align-self: flex-start;
  cursor: pointer;
  font-size: 8px;
  font-weight: bold;
  color: black;
}

.modal.edit-modal .close-button{
  position: relative;
  top: 50px;
  left: 50px;
  color: beige;
}