.waiting {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.waiting__wrapper {
  background-color: #ffffff;
  height: 719px;
  width: 414px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.waiting__rectangle {
  background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
  border-radius: 0px 0px 5px 5px;
  height: 29px;
  left: 0;
  top: 0;
  width: 414px;
}

.waiting__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 127px;
}

.waiting__logo {
  width: 200px;
}

.waiting__text {
  font-family: "Inter-Bold", Helvetica;
  color: #013a68;
  direction: rtl;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
}

.waiting__text-small {
  font-weight: 400;
  font-size: 24px;
  color: #013a68;
  margin: 0;
}

.waiting .waiting-buttons-wrapper {
  margin-top: 30px;
  text-align: center;
}
.waiting .button-active-instance {
  margin-top: 10px;
  margin-bottom: 10px;
}
