.Training-container {
    align-items: flex-end;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-right: '5%';
    padding-left: '5%';
    position: relative;
    width: 100%;
  }
  
  .Training-container .training-div {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 95%;
  }


  
  .Training-container .training-text-wrapper {
    color: #303030;
    direction: rtl;
    font-family: "Rubik-Regular", Helvetica;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: left;
    width: 45%;
  }
  
  .Training-container .training-text-wrapper-2 {
    color: #303030;
    direction: rtl;
    font-family: "Rubik-SemiBold", Helvetica;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .Training-container .training-div:nth-child(even){
    background-color: rgb(238, 238, 238)
  }