.active-guards {
    background-color: #ffffff;
    width: 100%;
    height: fit-content;
    padding-bottom: 100px;
}

.active-guards .div-2 {
    background-color: #ffffff;
    height: 650px;
    /* overflow: hidden; */
    /* position: relative; */
    width: 414px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.active-guards .active-guard-info {
    align-items: center;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 30px 0 39px;
    margin-bottom: 4px;
    margin-top: 24px;
}

.active-guards .frame-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 26px;
    position: relative;
}

.active-guards .group-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.active-guards .group {
    height: 19px;
    margin-right: -2px;
    position: relative;
    width: 38px;
}

.active-guards .text-wrapper-6 {
    color: #444444;
    font-family: "Rubik", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 0;
    white-space: nowrap;
}

.active-guards .div-wrapper {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 26px;
    position: relative;
}

.active-guards .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
}

.active-guards .group-2 {
    height: 28px;
    margin-right: -2px;
    position: relative;
    width: 155px;
}

.active-guards .text-wrapper-7 {
    color: #012c55;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.active-guards .frame-4 {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: auto;
    gap: 24px;
    margin-bottom: 32px;
    position: relative;
    /* Relative positioning to anchor the dropdown */
}

.users-dropdown {
    position: absolute;
    top: 100%;
    /* Position it right below the button */
    left: 39px;
    z-index: 9999;
    /* High z-index to ensure it overlays other elements */
    width: 200px;
    /* Adjust width as per your design */
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    padding-top: 40px;
    overflow-y: auto;
    /* Scrollable if content is too long */
}

.users-dropdown .close-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #ffffff;
    border: none;
    border-radius: 50%; /* Makes the button circular */
    font-size: 16px;
    cursor: pointer;
    width: 24px; /* Set width and height to make a circle */
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a slight shadow for elevation */
}

.users-dropdown .close-btn:hover {
    background-color: #f0f0f0; /* Changes the background color on hover */
}

/* Styling for individual options, adjust as needed */
.users-dropdown .option {
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    direction: rtl;
}

.users-dropdown .option:hover {
    background-color: #f0f0f0;
}

.users-dropdown .clear-all-btn {
    text-align: center;
    color: white;
    border: 1px solid black;
    border-radius: 10px;
    background: linear-gradient(#013D70, #014886);
    padding: 6px 12px;
    width: 100%;
    margin-top: 10px;
    opacity: 1;
    transition: opacity 0.3s; /* Adds transition for hover effect */
    cursor: pointer;
}

.users-dropdown .clear-all-btn:hover {
    opacity: 0.9;
}

.active-guards .bi-filter {
    height: 40px;
    /* position: relative; */
    width: 40px;
}

.active-guards .overlap-group-wrapper {
    height: 40px;
    width: 100%;
    position: relative;
    /* Ensures the absolute positioning of the icon is relative to this container */
}

.active-guards .overlap-group-2 {
    box-sizing: border-box;
    background-color: #f0f0f0;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-left: 30px;
    /* Added padding to prevent text from overlapping with the icon */

}

.active-guards .text-wrapper-8 {
    color: #303030;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    text-align: right;
    /* Adjusted from center to right to align text */
    flex-grow: 1;
    /* Allows the input to take up the available space */
    border: none;
    /* Removes the default border */
    background-color: transparent;
    /* Makes the background transparent */
    outline: none;
    /* Removes the outline when focused */
    height: 100%;
    padding-right: 10px;
    width: 90%;
}

.active-guards .ri-search-line {
    position: absolute;
    left: 10px;
    font-size: 19px;
    color: #303030;
    pointer-events: none;
    /* Disallow interaction with the icon */
}

.active-guards .frame-5 {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    /* padding: 0 4px 0 28px; */
    height: 400px;
    overflow-y: auto;
    align-items: center;
    /* margin-bottom: 100px; */
}

.active-guards .header {
    width: 100%;
}

.active-guards .overlap {
    background-color: #012c55;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;
    justify-content: space-around;
}

.active-guards .text-wrapper-9 {
    color: #ffffff;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
}

.active-guards .frame-6 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 20px;
    position: absolute;
    top: 98px;
    width: 364px;
}

.active-guards .frame-7 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
}

.active-guards .overlap-wrapper {
    height: 30px;
    position: relative;
    width: 49px;
}

.active-guards .overlap-2 {
    height: 30px;
    position: relative;
    width: 51px;
}

.active-guards .frame-8 {
    align-items: flex-start;
    display: inline-flex;
    left: 0;
    position: absolute;
    top: 5px;
}

.active-guards .ellipse {
    height: 25px;
    position: relative;
    width: 25px;
}

.active-guards .img {
    height: 25px;
    margin-left: -12.5px;
    position: relative;
    width: 25px;
}

.active-guards .group-3 {
    height: 24px;
    left: 25px;
    position: absolute;
    top: 0;
    width: 26px;
}

.active-guards .overlap-group-3 {
    background-color: #dc0000;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
}

.active-guards .text-wrapper-10 {
    color: #ffffff;
    font-family: "Rubik", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 4px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 4px;
}

.active-guards .mdi-user-add {
    height: 30px;
    position: relative;
    width: 30px;
}

.active-guards .text-wrapper-11 {
    color: #ffffff;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.activeGuard-logout {}


/* .active-guards .nav-bar-instance {
    left: 0 !important;
    position: fixed !important;
    top: 656px !important;
  }
  
  .active-guards .nav-bar-2 {
    margin-left: -1px !important;
  }
  
  .active-guards .nav-bar-3 {
    height: 24px !important;
    left: 10px !important;
    top: 0 !important;
    width: 24px !important;
  } */

.items-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
}