.nav-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 4px 4px #0000001a, 0px -4px 10px #00000005;
    height: 80px;
    width: 100%;
    z-index: 1;
}



.nav-bar .frame-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}


.active {
    color: #013e68; 
  }
  
  .gray {
    color: gray; 
  }

