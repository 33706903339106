.component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  

  
  .component .text-wrapper-6 {
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    white-space: nowrap;
  }
  
  