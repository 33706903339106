:root {
  --h4-font-family: "Rubik-Regular", Helvetica;
  --h4-font-size: 24px;
  --h4-font-style: normal;
  --h4-font-weight: 400;
  --h4-letter-spacing: 0px;
  --h4-line-height: normal;
}


