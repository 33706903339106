.swipe-to-delete {
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.swipe-to-delete .swipe {
    width: 100%;
}

.swipe-to-delete .card-instance {
    /* z-index: 2 !important; */
}

.swipe-to-delete .class {
    /* margin-right: -23992.94px !important;
    margin-top: -95.67px !important;
    z-index: 0 !important; */
}


/* .swipe-to-delete .class-2 {
    z-index: 0 !important;
}

.swipe-to-delete .class-3 {
    background-color: #ebebeb !important;
    flex: 0 0 auto !important;
    margin-left: -344px !important;
    z-index: 1 !important;
} */

.swipe-to-delete .class-4 {
    background-color: #fcfcfc;
    width: 100%;
    z-index: 1;
}


/* .swipe-to-delete .class-5 {
    background-color: #f4f4f4 !important;
    border-radius: 8px !important;
    flex: 0 0 auto !important;
    width: 370px !important;
    z-index: 1 !important;
}

.swipe-to-delete .instance-node {
    z-index: 1 !important;
}

.swipe-to-delete .delete-icon-9 {
    height: 30px !important;
    left: 319px !important;
    position: absolute !important;
    top: 23px !important;
    width: 27px !important;
    z-index: 0 !important;
}

.swipe-to-delete .delete-icon-8 {
    height: 26px !important;
    left: 401px !important;
    position: absolute !important;
    top: 26px !important;
    width: 23px !important;
    z-index: 0 !important;
}

.swipe-to-delete .delete-icon-instance {
    height: 26px !important;
    left: 23930px !important;
    top: -120px !important;
    width: 23px !important;
    z-index: 0 !important;
} */

.swipe-to-delete.default {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 17px 0;
    margin: 0 10px;
}

.swipe-to-delete.delete {
    align-items: flex-end;
    display: flex;
    padding: 6px 0px;
    width: 357px;
}

.swipe-to-delete.deleted-message {
    align-items: flex-start;
    display: inline-flex;
    justify-content: center;
    padding: 5px 0px;
}

.swipe-to-delete.default .swipe {
    align-items: flex-start;
    background-color: #ff6161;
    border-radius: 5px;
    display: inline-flex;
    flex: 0 0 auto;
}

.swipe-to-delete.delete .swipe {
    align-items: flex-start;
    background-color: #ff6161;
    border-radius: 5px;
    display: inline-flex;
    flex: 0 0 auto;
    margin-left: -103px;
    padding: 0px 90px 0px 0px;
}

.swipe-to-delete.deleted-message .swipe {
    align-items: flex-end;
    border-radius: 20px;
    display: flex;
    height: 1px;
    padding: 0px 357px 0px 0px;
    width: 370px;
}

.SwipeToDelete__buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    gap: 16px;
}

button.button.mangeEvent {
    max-width: 129px;
    max-height: 39px;
}

button.button.mangeEvent.delete {
    background: #FCFCFC;
    border: 1px solid rgba(1, 72, 134, 1);
    color: rgba(1, 72, 134, 1);
}