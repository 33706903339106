.card {
    align-items: center;
    border-radius: 5px;
    display: flex;
    gap: 16px;
}

.card .frame {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 46px;
    justify-content: space-between;
}

.card .element-wrapper {
    align-items: flex-start;
    background-color: #ff0000;
    border-radius: 15px;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: space-around;
    position: relative;
    width: 66px;
}

.card .element {
    color: #ffffff;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 15px;
    font-weight: 400;
    height: 20px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: 66px;
}

.card .text-wrapper {
    color: #ff3e3e;
    direction: rtl;
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.card .text {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 5px;
    padding: 0px 15px 0px 0px;
    position: relative;
}

.card .div {
    align-self: stretch;
    color: #222222;
    direction: rtl;
    font-family: "Rubik", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
}

.card .p {
    align-self: stretch;
    color: #444444;
    direction: rtl;
    font-family: "DM Sans", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    margin: 0;
}

.card .subtract {
    height: 21.33px;
    position: relative;
    width: 29.44px;
    color: #D00;
}