.property-default-wrapper {
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.property-default-wrapper .swipe-2 {
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    position: relative;
    background-color: #f5f5f5;;
}


/* .property-default-wrapper .new-member-instance {
    z-index: 1 !important;
}

.property-default-wrapper .new-member-2 {
    z-index: 2 !important;
} */

.property-default-wrapper .class-6 {
    margin-right: -23422.5px !important;
    margin-top: -88px !important;
    z-index: 0 !important;
}

.property-default-wrapper .avatar.class-7 {
    z-index: 0 !important;
}

.property-default-wrapper .class-8 {
    background-color: #f4f4f4 !important;
    border-radius: 10px !important;
    flex: 0 0 auto !important;
    width: 370px !important;
    z-index: 1 !important;
}

.property-default-wrapper .class-9 {
    background-color: #ebebeb !important;
    flex: 0 0 auto !important;
    margin-left: -344px !important;
    z-index: 1 !important;
}

.property-default-wrapper .class-10 {
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
    padding: 0px 10px;
}

.property-default-wrapper .delete-icon-2 {
    height: 30px;
    left: 319px;
    top: 34px;
    width: 27px;
    filter: invert(42%) sepia(43%) saturate(1352%) hue-rotate(331deg) brightness(119%) contrast(49%);
}

.property-default-wrapper.property-1-0-default {
    padding: 6px 0px;
    width: 100%;
    display: flex;
}

.property-default-wrapper.property-1-0-delete {
    align-items: flex-end;
    display: flex;
    padding: 6px 0px;
    width: 357px;
}

.property-default-wrapper.property-1-0-deleted-message {
    align-items: flex-start;
    display: inline-flex;
    justify-content: center;
    padding: 5px 0px;
}

.swipe-2 {
    align-items: flex-start;
    background-color: #ff6161;
    border-radius: 5px;
    display: inline-flex;
    flex: 0 0 auto;
}

.property-default-wrapper.property-1-0-delete .swipe-2 {
    align-items: flex-start;
    background-color: #ff6161;
    border-radius: 5px;
    display: inline-flex;
    flex: 0 0 auto;
    margin-left: -103px;
    padding: 0px 90px 0px 0px;
}

.property-default-wrapper.property-1-0-deleted-message .swipe-2 {
    align-items: flex-end;
    border-radius: 20px;
    display: flex;
    height: 1px;
    padding: 0px 357px 0px 0px;
    width: 370px;
}


.loading-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

