.login-page {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
}

.login-page .div-2 {
    background-color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-page .overlap {
    height: 188px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-page .frame {
    background-color: #fff;
    align-items: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-page .form-input-area {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
}

.login-page .component {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}


/* .login-page .component-22 {
    position: relative !important;
}

.login-page .component-instance {
    border-color: #013d70 !important;
}

.login-page .component-22-instance {
    left: 198px !important;
}

.login-page .design-component-instance-node {
    left: 237px !important;
} */

.login-page .magen-ezrahi {
    /* height: 259px; */
    height: 150px;
    padding: 49px 0px;
    /* object-fit: cover; */
    object-fit: scale-down;
    /* width: 240px; */
    width: 224px;
    /* top: 51px; */
    top: 77px;
    position: absolute;
    /* border: 1px solid white; */
    /* border-radius: 50px; */
    border-radius: 150px;
    background: white;
}

.login-page .subtract {
    height: 188px;
    width: 100%;
}

.login-page .overlap-wrapper {
    width: 100%;
}

.login-page .overlap-2 {
    border-radius: 5px;
    display: flex;
    padding: 140px 0 0 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-page .rectangle {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 5px;
    height: 60px;
    left: 0;
    position: absolute;
    top: 0;
    width: 314px;
}

.login-page .text-wrapper-2 {
    color: #ffffff;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    /* left: 112px; */
    letter-spacing: 0;
    line-height: normal;
    /* position: absolute; */
    text-align: left;
    /* top: 16px; */
}

.login-page .rectangle-2 {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    border-radius: 0px 0px 5px 5px;
    height: 29px;
    transform: rotate(-180deg);
    width: 100%;
    /* position: relative; */
    position: fixed;
    margin-top: 41px;
    padding: 0;
    bottom: 0;
}

.login-page .frame-2 {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    padding: 0;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 76px;
}

.login-page .text-wrapper-3 {
    color: #014886;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    width: fit-content;
    background: unset;
    border: unset;
    padding: 0;
}

.last-text-element-class {
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0;
    text-align: center;
}

.login-page .home-screen {}