.select {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    position: relative;
}

.select .close-btn{
    align-self: start;
    position: absolute;
    top: 35px;
    height: fit-content;
}

.select__main {
    color: #013d70;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    top: 12px;
    overflow: hidden;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000014;
    height: 50px;
    width: 100%;
}

.select__options {
    width: 100%;
    background-color: #fff;
    direction: rtl;
    border: 1px solid;
    font-size: 1.25rem;
    /* padding-right: 5px; */
    padding: 3px 5px 3px 0;
}

.select__option:hover {
    background: #0a00ef;
    color: white;
}

@media screen and (max-width: 430px) {
    .select__main {
        color: #013d70;
        direction: rtl;
        font-family: "Inter-Regular", Helvetica;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        top: 12px;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        box-shadow: 0px 2px 10px #00000014;
        width: 100%;
        height: 50px;
    }
}