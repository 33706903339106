.ca-two {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.ca-two .div-2 {
    background-color: #ffffff;
    position: relative;
    width: 414px;
    height: 100%;
}

.ca-two .rectangle-2 {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    height: 29px;
    width: 100%;
}

.ca-two .group {
    height: 15px;
    left: 51px;
    position: absolute;
    top: 71px;
    width: 313px;
}

.ca-two .overlap-group-2 {
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 149px;
}

.ca-two .progress-one {
    position: relative;
    text-align: center;
    top: -30px;
    
}
.ca-two .steps-btn-container {
    position: absolute;
    top: 30px;
    width:100%;
    height: 50px;
    justify-content: space-between;
    display:flex;
    align-items: center;
}
.ca-two .steps-btn-container .box{
    /* margin-top: 40px; */
    width: 33%;
    height: 50px;
}


.ca-two .img {
    height: 1px;
    left: 8px;
    object-fit: cover;
    position: absolute;
    top: 6px;
    width: 141px;
}

.ca-two .ellipse {
    background-color: #d9d9d9;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 15px;
}

.ca-two .overlap {
    height: 15px;
    left: 149px;
    position: absolute;
    top: 0;
    width: 164px;
}

.ca-two .line-2 {
    height: 1px;
    left: 11px;
    object-fit: cover;
    position: absolute;
    top: 6px;
    width: 141px;
}

.ca-two .ellipse-2 {
    background-color: #00840d;
    border-radius: 7.5px;
    height: 15px;
    left: 149px;
    position: absolute;
    top: 0;
    width: 15px;
}

.ca-two .ellipse-3 {
    background-color: #013d70;
    border-radius: 7.5px;
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 15px;
}

ca-two .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 61px;
    position: absolute;
    top: 102px;
}

.ca-two .frame-3 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 30px;
}

.ca-two .frame-4 {
    align-items: flex-end;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.ca-two .text-wrapper-4 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Bold", Helvetica;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: left;
    width: fit-content;
}

.ca-two .text-wrapper-5 {
    color: #013a68;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: left;
    width: fit-content;
}

.ca-two .frame-wrapper {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
}

.ca-two .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
}


/* .ca-two .component-23 {
    position: relative !important;
}

.ca-two .component-instance {
    left: 84px !important;
}

.ca-two .component-23-instance {
    left: 113px !important;
}

.ca-two .design-component-instance-node {
    left: 67px !important;
}

.ca-two .component-2 {
    left: 78px !important;
} */

.ca-two .overlap-wrapper {
    height: 60px;
    margin-right: -2px;
    position: relative;
    width: 316px;
}

.ca-two .overlap-2 {
    border-radius: 5px;
    height: 60px;
    position: relative;
    width: 314px;
}

.ca-two .rectangle-3 {
    background-color: #013d70;
    border-radius: 5px;
    height: 60px;
    left: 0;
    position: absolute;
    top: 0;
    width: 314px;
}

.ca-two .text-wrapper-6 {
    color: #ffffff;
    direction: rtl;
    font-family: "Inter-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    left: 107px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: left;
    top: 14px;
    width: 100px;
}

.frame-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 90%;
}