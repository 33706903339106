.event-details {
    z-index: 10;
    background: #ffff;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    height: fit-content;
    gap: 50px;
}

.event-details__header {
    background: linear-gradient(180deg, rgb(1, 47, 81) 0%, rgb(1, 72, 134) 100%);
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Inter-Bold", Helvetica;
}

.event-details__logo {
    width: 80px;
    height: 80px;
    border-radius: 30%;
}
.event-details__title {
    color: #ffffff;
    font-size: 28px;
}
.event-details__home {
    color: #ffffff;
    /* width: 50px;
    height: 50px; */
}

.event-details__table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
}

td {
    border: 1px solid #000;
    padding: 8px;
}

tr {
    direction: rtl;
}

col.left {
    width: 50%;
}

col.right {
    width: 50%;
    border-left: 1px solid #000;
}

.event-details__map {
    width: 80%;
    height: 20vh;
}

.event-details .btns-wrapper{
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
}
.event-details .btns-wrapper button{
    padding: 10px;
    font-size: 18px;
    background-color: #013D70;
    color: white;
    border-radius: 5px;
    border: none;
    margin: 0 10px;
}
.event-details .btns-wrapper button.active{
    color: #013D70;
    background-color: white;
}
.event-details .details-table-wrapper{
    width: 90%;
    height: 30vh;
    /* padding-bottom: 160px; */
    overflow-y: auto;
}
.event-details .active-guards-display-wrapper{
    height: 30vh;
    width: 90%;
    overflow-y: auto;
    /* padding-bottom: 160px; */
}
.event-details .go-back-btn{
    height: 50px;
    width: 90%;
    max-width: 400px;
    color: white;
    background-color: #013D70;
    border: none;
    /* padding-bottom: 160px; */
}
.event-details .dummy-bottom{
    height: 160px;
}
.event-details .footer-wrapper{
    position: fixed;
    bottom: 0;
    border-top: 1px solid rgb(201, 201, 201);
    left: 0;
    width: 100%;
    max-width: 100vw;
    height: 80px;
    background-color: rgb(249, 249, 249);
    display: flex;
    justify-content: space-between;
}
.event-details .footer-wrapper .open-ways-app-btn{
    font-family: Rubik;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #013D70;
    margin: auto 20px;
    text-align: center;
    text-decoration: none;
}
.event-details .footer-wrapper .close-event-btn{
    font-family: Rubik;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #013D70;
    margin: auto 20px;
    text-align: center;
}
.event-details .footer-wrapper .open-ways-app-btn svg{
    width: 33px;
    height: 33px;
    margin: auto;
}
.event-details .footer-wrapper .close-event-btn svg{
    width: 33px;
    height: 33px;
    margin: auto;
}
.event-details .footer-wrapper .walkie-talkie-btn{
    width: 60px;
    height: 60px;
    border: 15px solid #013D70;
    position: relative;
    top: -45px;
    background-color: #013D70;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
    padding: auto;
}
.event-details .footer-wrapper .muted-btn{
    width: 60px;
    height: 60px;
    border: 15px solid #888888;
    position: relative;
    top: -45px;
    background-color: #888888;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
    padding: auto;
}
.event-details .footer-wrapper .muted-btn .muted-icon{
    color: white;
    width: 60px;
    height: 60px;
    margin: auto;
}
.event-details .footer-wrapper .walkie-talkie-btn img{
    width: 60px;
    height: 60px;
    margin: auto;
}
.event-details .footer-wrapper .walkie-talkie-btn.active{
    background-color: #04ac04;
    border-color: rgb(4, 172, 4);
}

/* .ask-cancel-screen-wrapper .cancel-event .cancel-event__wrapper{
    width: 380px;
    margin: 20px;
} */
.ask-cancel-screen-wrapper .cancel-event .cancel-event__wrapper .cancel-event__button-active{
    width: 250px;
    left: 75px !important;
}
/* .ask-cancel-screen-wrapper .cancel-event .cancel-event__wrapper .location{
    left: 140px;
} */